import * as React from 'react';
import { ShowIf } from './helpers';
import { FormTitle, FieldList, FieldItem, FieldInfo, FieldMessage, FieldError, FieldSet, FormFooter, Checkbox, TextField } from './form';
import Uploader from './uploader';
import * as styles from './form.module.scss';
const globalConfig = require('./ovic-form.json');
const formConfig = require('./privacy-complaint-form.json');

const PrivacyComplaintFormStep4 = (props) => {
  const {
    formData, 
    formErrors,
    formFiles,
    onChange, 
    onSubmit,
    onPrevious,
    onUpdateFiles,
  } = props;

  const showUploader = 
    formData.attachmentEvidencePrivacyBreached
    || formData.attachmentOrganisationCorrespondence
    || formData.attachmentResponse
    || formData.attachmentOtherCorrespondence
    || formData.attachmentEvidence
    || formData.attachmentRegulatorCorrespondence;

  const uploaderInvalid = formErrors && formFiles.length === 0 && showUploader;

  return (
    <form
      className={styles.page}
      onSubmit={onSubmit}
      noValidate>
      <FormTitle 
        text={formConfig.steps[3]}
      />
      <FieldList>
        <FieldItem>
          <FieldInfo 
            {...formConfig.attachmentDetails}
          />
          <FieldSet>
            <Checkbox 
              label="Evidence showing that your privacy has been breached."
              name="attachmentEvidencePrivacyBreached"
              value={formData.attachmentEvidencePrivacyBreached}
              error={formErrors.attachmentEvidencePrivacyBreached}
              onChange={onChange}
            />
            <Checkbox 
              label="Correspondence you sent to the organisation raising your privacy complaint."
              name="attachmentOrganisationCorrespondence"
              value={formData.attachmentOrganisationCorrespondence}
              error={formErrors.attachmentOrganisationCorrespondence}
              onChange={onChange}
            />
            <Checkbox 
              label="The organisation’s response to your privacy complaint."
              name="attachmentResponse"
              value={formData.attachmentResponse}
              error={formErrors.attachmentResponse}
              onChange={onChange}
            />
            <Checkbox 
              label="Any other correspondence between you and the organisation."
              name="attachmentOtherCorrespondence"
              value={formData.attachmentOtherCorrespondence}
              error={formErrors.attachmentOtherCorrespondence}
              onChange={onChange}
            />
            <Checkbox 
              label="Documents or evidence showing the harm that you have suffered."
              name="attachmentEvidence"
              value={formData.attachmentEvidence}
              error={formErrors.attachmentEvidence}
              onChange={onChange}
            />
            <FieldMessage
              plain={true}>
              <p>You may wish to include things such as:</p>
              <ul>
                <li>Invoices (e.g. if you are seeking reimbursement); or</li>
                <li>A report from your doctor (e.g. if you have suffered psychological harm).</li>
              </ul>
            </FieldMessage>
            <Checkbox 
              label="Any correspondence between you and another organisation if you complained to another organisation about the same or similar issue in the past."
              name="attachmentRegulatorCorrespondence"
              value={formData.attachmentRegulatorCorrespondence}
              error={formErrors.attachmentRegulatorCorrespondence}
              onChange={onChange}
            />
            <Checkbox 
              label="Other"
              name="attachmentOther"
              value={formData.attachmentOther}
              error={formErrors.attachmentOther}
              onChange={onChange}
            />
            <ShowIf cond={formData.attachmentOther !== ''}>
              <TextField 
                type="text"
                label="Other attachment details"
                name="attachmentOtherDescription"
                value={formData.attachmentOtherDescription}
                error={formErrors.attachmentOtherDescription}
                onChange={onChange}
              />
            </ShowIf>
          </FieldSet>
        </FieldItem>
        <ShowIf cond={showUploader}>
          <FieldItem 
            invalid={uploaderInvalid}>
            <Uploader 
              maxFiles={globalConfig.uploads.maxFiles}
              maxSize={globalConfig.uploads.maxSize}
              fileTypes={globalConfig.uploads.fileTypes}
              fileTypesText={globalConfig.uploads.fileTypesText}
              onUpdateFiles={onUpdateFiles}>
              {uploaderInvalid && (
                <FieldError 
                  inline={true}
                  name="files"
                  error="Please upload the file(s) you have selected above"
                />
              )}
            </Uploader>
            <input 
              name="files"
              value={formFiles.length || ''}
              required={true}
              onChange={onChange}
              style={{display: 'none'}}
            />
          </FieldItem>
        </ShowIf>
      </FieldList>
      <FormFooter 
        next="Next: Declaration"
        onPrevious={onPrevious}
      />
    </form>
  )
}

export default PrivacyComplaintFormStep4;
