import * as React from 'react';
import { FormTitle, FormConfirmation } from './form';
import * as styles from './form.module.scss';
const formConfig = require('./privacy-complaint-form.json');

const PrivacyComplaintFormStep5 = () => {
  return (
    <form
      className={styles.page}>
      <FormTitle 
        text={formConfig.steps[5]}
      />
      <FormConfirmation>
        <p>You will now receive an email with a copy of your complaint for your own records.</p>
        <p>To find out what you can expect next from our privacy complaints process, please see our guidance on <a href="https://ovic.vic.gov.au/privacy/for-the-public/privacy-complaints/step-3-how-ovic-handles-privacy-complaints/" target="_blank" rel="noopener noreferrer">how OVIC handles privacy complaints</a>.</p>
      </FormConfirmation>
    </form>
  )
}

export default PrivacyComplaintFormStep5;
