import * as React from 'react';
import { ShowIf } from './helpers';
import { Container } from './grid';
import OVICForm from './ovic-form';
import FormHeader from './form-header';
import FormError from './form-error';
import FormLoading from './form-loading';
import PrivacyComplaintFormStep1 from './privacy-complaint-form-step-1';
import PrivacyComplaintFormStep2 from './privacy-complaint-form-step-2';
import PrivacyComplaintFormStep3 from './privacy-complaint-form-step-3';
import PrivacyComplaintFormStep4 from './privacy-complaint-form-step-4';
import PrivacyComplaintFormStep5 from './privacy-complaint-form-step-5';
import PrivacyComplaintFormStep6 from './privacy-complaint-form-step-6';
import * as styles from './form.module.scss';
const formConfig = require('./privacy-complaint-form.json');

class PrivacyForm extends OVICForm 
{
  constructor(props) {
    super(props);

    this.state = {
      form: 'privacycomplaint',
      step: 1,
      steps: 6,
      files: [],
      filesList: [],
      loading: false,
      percentLoaded: 0,
      formData: {
        isYourPrivacyComplaint: '',
        organisationName: '',
        previousComplaint: '',
        previousComplaintOutcome: '',
        previousComplaintReason: '',
        similarComplaint: '',
        similarComplaintOutcome: '',
        complainantTitle: '',
        complainantFirstName: '',
        complainantSurname: '',
        complainantOrganisation: '',
        complaintOnBehalf: '',
        representativeTitle: '',
        representativeFirstName: '',
        representativeSurname: '',
        representativeRelationship: '',
        representativeOrganisation: '',
        representativeAuthority: '',
        contactPhone: '',
        contactEmail: '',
        contactMail: '',
        complaintDescription: '',
        complaintAffected: '',
        complaintResolve: '',
        attachmentEvidencePrivacyBreached: '',
        attachmentOrganisationCorrespondence: '',
        attachmentResponse: '',
        attachmentOtherCorrespondence: '',
        attachmentEvidence: '',
        attachmentRegulatorCorrespondence: '',
        attachmentOther: '',
        attachmentOtherDescription: '',
      },
      formErrors: {
      },
    }
  }

  render() {
    return (
      <div 
        className={styles.root}
        ref={this.rootRef}>
        <FormHeader
          title={formConfig.title}
          steps={formConfig.steps}
          step={this.state.step}
        />
        <Container>
          <PrivacyComplaintFormStep1 
            onInput={this.handleInput}
            onChange={this.handleChange}
            onSubmit={this.handleSubmit}
            onPrevious={this.handlePrevious}
            onUpdateFiles={this.handleUpdateFiles}
            formData={this.state.formData}
            formErrors={this.state.showErrors ? this.state.formErrors : false}
          />
          <PrivacyComplaintFormStep2 
            onInput={this.handleInput}
            onChange={this.handleChange}
            onSubmit={this.handleSubmit}
            onPrevious={this.handlePrevious}
            onUpdateFiles={this.handleUpdateFiles}
            formData={this.state.formData}
            formErrors={this.state.showErrors ? this.state.formErrors : false}
          />
          <PrivacyComplaintFormStep3 
            onInput={this.handleInput}
            onChange={this.handleChange}
            onSubmit={this.handleSubmit}
            onPrevious={this.handlePrevious}
            onUpdateFiles={this.handleUpdateFiles}
            formData={this.state.formData}
            formErrors={this.state.showErrors ? this.state.formErrors : false}
          />
          <PrivacyComplaintFormStep4
            onInput={this.handleInput}
            onChange={this.handleChange}
            onSubmit={this.handleSubmit}
            onPrevious={this.handlePrevious}
            onUpdateFiles={this.handleUpdateFiles}
            formData={this.state.formData}
            formErrors={this.state.showErrors ? this.state.formErrors : false}
            formFiles={this.state.files}
          />
          <PrivacyComplaintFormStep5
            onInput={this.handleInput}
            onChange={this.handleChange}
            onSubmit={this.handleSubmit}
            onPrevious={this.handlePrevious}
            onUpdateFiles={this.handleUpdateFiles}
            formData={this.state.formData}
            formErrors={this.state.showErrors ? this.state.formErrors : false}
            formFiles={this.state.files}
          />
          <PrivacyComplaintFormStep6 
            onInput={this.handleInput}
            onChange={this.handleChange}
            onSubmit={this.handleSubmit}
            onPrevious={this.handlePrevious}
            onUpdateFiles={this.handleUpdateFiles}
            formData={this.state.formData}
            formErrors={this.state.showErrors ? this.state.formErrors : false}
          />
        </Container>
        <ShowIf cond={this.state.loading}>
          <FormLoading 
            percent={this.state.percentLoaded}
          />
        </ShowIf>
        <ShowIf cond={this.state.submitError}>
          <FormError>
            <p>This form could no be submitted due to a system error.</p>
          </FormError>
        </ShowIf>
      </div>
    )
  }
}

export default PrivacyForm;
