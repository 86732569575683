import * as React from 'react';
import Layout from '../components/layout';
import PrivacyComplaintForm from '../components/privacy-complaint-form';
const formConfig = require('../components/privacy-complaint-form.json');

const PrivacyComplaintPage = () => (
  <Layout
    title={formConfig.title}
    storageKey="privacycomplaint_stored"
  >
    <PrivacyComplaintForm 
    />
  </Layout>
)

export default PrivacyComplaintPage;
