import * as React from 'react';
import { FormTitle, FieldList, FieldItem, FieldInfo, FormFooter, TextArea } from './form';
import * as styles from './form.module.scss';
const globalConfig = require('./ovic-form.json');
const formConfig = require('./privacy-complaint-form.json');

const PrivacyComplaintFormStep3 = (props) => {
  const {
    formData, 
    formErrors,
    onChange, 
    onSubmit,
    onPrevious,
  } = props;

  return (
    <form
      className={styles.page}
      onSubmit={onSubmit}
      noValidate>
      <FormTitle 
        text={formConfig.steps[2]}
      />
      <FieldList>
        <FieldItem>
          <FieldInfo 
            {...formConfig.complaintDescription}
          />
          <TextArea 
            label=""
            name="complaintDescription"
            value={formData.complaintDescription}
            error={formErrors.complaintDescription}
            required={true}
            onChange={onChange}
            characterCount={true}
            attrs={{
              maxLength: globalConfig.charLimits.long,
            }}
          />
        </FieldItem>
        <FieldItem>
          <FieldInfo 
            {...formConfig.complaintAffected}
          />
          <TextArea 
            label=""
            name="complaintAffected"
            value={formData.complaintAffected}
            error={formErrors.complaintAffected}
            required={true}
            onChange={onChange}
            characterCount={true}
            attrs={{
              maxLength: globalConfig.charLimits.long,
            }}
          />
        </FieldItem>
        <FieldItem>
          <FieldInfo 
            {...formConfig.complaintResolve}
          />
          <TextArea 
            label=""
            name="complaintResolve"
            value={formData.complaintResolve}
            error={formErrors.complaintResolve}
            required={true}
            onChange={onChange}
            characterCount={true}
            attrs={{
              maxLength: globalConfig.charLimits.medium,
            }}
          />
        </FieldItem>
      </FieldList>
      <FormFooter 
        next="Next: Supporting documents and attachments"
        onPrevious={onPrevious}
      />
    </form>
  )
}

export default PrivacyComplaintFormStep3;
