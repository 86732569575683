import * as React from 'react';
import { ShowIf } from './helpers';
import { FormTitle, FieldSet, FieldList, FieldItem, FieldInfo, FieldMessage, FieldError, FormFooter, TextField, TextArea, NoYesGroup, SelectMenu, Checkbox } from './form';
import * as styles from './form.module.scss';
const globalConfig = require('./ovic-form.json');
const formConfig = require('./privacy-complaint-form.json');

const PrivacyComplaintFormStep2 = (props) => {
  const {
    formData, 
    formErrors,
    onInput,
    onChange, 
    onSubmit,
    onPrevious,
  } = props;

  return (
    <form
      className={styles.page}
      onSubmit={onSubmit}
      noValidate>
      <FormTitle 
        text={formConfig.steps[1]}
      />
      <FieldList>
        <FieldItem>
          <FieldInfo 
            {...formConfig.isYourPrivacyComplaint}
          />
          <NoYesGroup 
            name="isYourPrivacyComplaint"
            value={formData.isYourPrivacyComplaint}
            error={formErrors.isYourPrivacyComplaint}
            required={true}
            onChange={onChange}
          />
        </FieldItem>
      </FieldList>
      <ShowIf cond={formData.isYourPrivacyComplaint === 'No'}>
        <FieldInfo>
          <p>Unfortunately we are unable to assist you. <a href="https://ovic.vic.gov.au/privacy/for-the-public/privacy-complaints/step-2-making-a-complaint-to-ovic/#who-else-might-be-able-to-help-me" target="_blank" rel="noopener noreferrer">Find information on other organisations  that may be able to assist you.</a></p><br/><br/>
        </FieldInfo>
      </ShowIf>
      <ShowIf cond={formData.isYourPrivacyComplaint === 'Yes'}>
        <FieldList>
          <FieldItem>
            <FieldInfo 
              {...formConfig.organisationName}
            />
            <TextField 
              type="text"
              label="Name of organisation"
              name="organisationName"
              value={formData.organisationName}
              error={formErrors.organisationName}
              required={true}
              onChange={onChange}
              characterCount={true}
              attrs={{
                maxLength: globalConfig.charLimits.short,
              }}
            />
          </FieldItem>
          <FieldItem>
            <FieldInfo 
              {...formConfig.previousComplaint}
            />
            <NoYesGroup 
              name="previousComplaint"
              value={formData.previousComplaint}
              error={formErrors.previousComplaint}
              required={true}
              onChange={onChange}
            />
            <ShowIf cond={formData.previousComplaint === 'Yes'}>
              <TextArea 
                label="In the space below, please detail the outcome of your complaint and why you disagree with it. Attach a copy of your complaint and any response the organisation provided."
                name="previousComplaintOutcome"
                value={formData.previousComplaintOutcome}
                error={formErrors.previousComplaintOutcome}
                required={true}
                onChange={onChange}
                characterCount={true}
                attrs={{
                  maxLength: globalConfig.charLimits.medium,
                }}
              />
            </ShowIf>
            <ShowIf cond={formData.previousComplaint === 'No'}>
              <TextArea 
                label="In the space below, please explain why you haven’t complained to the organisation."
                name="previousComplaintReason"
                value={formData.previousComplaintReason}
                error={formErrors.previousComplaintReason}
                required={true}
                onChange={onChange}
                characterCount={true}
                attrs={{
                  maxLength: globalConfig.charLimits.medium,
                }}
              />
            </ShowIf>
          </FieldItem>
          <FieldItem>
            <FieldInfo 
              {...formConfig.similarComplaint}
            />
            <NoYesGroup 
              name="similarComplaint"
              value={formData.similarComplaint}
              error={formErrors.similarComplaint}
              required={true}
              onChange={onChange}
            />
            <ShowIf cond={formData.similarComplaint === 'Yes'}>
              <TextArea 
                type="text"
                label="In the space below, please detail the outcome of your complaint. If you received a response from the organisation you complained to, please attach a copy to this form."
                name="similarComplaintOutcome"
                value={formData.similarComplaintOutcome}
                error={formErrors.similarComplaintOutcome}
                required={true}
                onChange={onChange}
                characterCount={true}
                attrs={{
                  maxLength: globalConfig.charLimits.medium,
                }}
              />
            </ShowIf>
          </FieldItem>
          <FieldItem>
            <FieldInfo 
              {...formConfig.complaintOnBehalf}
            />
            <NoYesGroup 
              name="complaintOnBehalf"
              value={formData.complaintOnBehalf}
              error={formErrors.complaintOnBehalf}
              required={true}
              onChange={onChange}
            />
          </FieldItem>
          <ShowIf cond={formData.complaintOnBehalf === 'Yes'}>
            <FieldItem>
              <FieldInfo 
                {...formConfig.representativeDetails}
              />
              <SelectMenu
                label="Title"
                name="representativeTitle"
                value={formData.representativeTitle}
                error={formErrors.representativeTitle}
                onChange={onChange}
                options={globalConfig.contactTitle}
              />
              <TextField 
                type="text"
                label="First Name"
                name="representativeFirstName"
                value={formData.representativeFirstName}
                error={formErrors.representativeFirstName}
                required={true}
                onChange={onChange}
                characterCount={true}
                attrs={{
                  maxLength: globalConfig.charLimits.xshort,
                }}
              />
              <TextField 
                type="text"
                label="Surname"
                name="representativeSurname"
                value={formData.representativeSurname}
                error={formErrors.representativeSurname}
                required={true}
                onChange={onChange}
                characterCount={true}
                attrs={{
                  maxLength: globalConfig.charLimits.xshort,
                }}
              />
              <TextField 
                type="text"
                label="Organisation (if applicable)"
                name="representativeOrganisation"
                value={formData.representativeOrganisation}
                error={formErrors.representativeOrganisation}
                onChange={onChange}
                characterCount={true}
                attrs={{
                  maxLength: globalConfig.charLimits.xshort,
                }}
              />
              <TextField 
                type="text"
                label="Your relationship"
                name="representativeRelationship"
                value={formData.representativeRelationship}
                error={formErrors.representativeRelationship}
                onChange={onChange}
                characterCount={true}
                attrs={{
                  maxLength: globalConfig.charLimits.xshort,
                }}
              />
            </FieldItem>
            <FieldItem>
              <FieldInfo 
                {...formConfig.representativeAuthority}
              />
              <Checkbox 
                label="I confirm I have authority to act for the person named above. I have their consent to receive and disclose their information and authority to resolve, finalise, or close this complaint."
                name="representativeAuthority"
                value={formData.representativeAuthority}
                error={formErrors.representativeAuthority}
                required={true}
                onChange={onChange}
              />
            </FieldItem>
          </ShowIf>
          <FieldItem>
            <FieldInfo 
              {...formConfig.complainantDetails}
            />
            <SelectMenu
              label="Title"
              name="complainantTitle"
              value={formData.complainantTitle}
              error={formErrors.complainantTitle}
              onChange={onChange}
              options={globalConfig.contactTitle}
            />
            <TextField 
              type="text"
              label="First Name"
              name="complainantFirstName"
              value={formData.complainantFirstName}
              error={formErrors.complainantFirstName}
              required={true}
              onChange={onChange}
              characterCount={true}
              attrs={{
                maxLength: globalConfig.charLimits.xshort,
              }}
            />
            <TextField 
              type="text"
              label="Surname"
              name="complainantSurname"
              value={formData.complainantSurname}
              error={formErrors.complainantSurname}
              required={true}
              onChange={onChange}
              characterCount={true}
              attrs={{
                maxLength: globalConfig.charLimits.xshort,
              }}
            />
            <TextField 
              type="text"
              label="Organisation (if applicable)"
              name="complainantOrganisation"
              value={formData.complainantOrganisation}
              error={formErrors.complainantOrganisation}
              onChange={onChange}
              characterCount={true}
              attrs={{
                maxLength: globalConfig.charLimits.xshort,
              }}
            />
          </FieldItem>
          <FieldItem>
            <FieldInfo 
              {...formConfig.contactDetails}
            />
            <FieldSet>
              <TextField 
                type="tel"
                label="Phone"
                name="contactPhone"
                value={formData.contactPhone}
                error={formErrors.contactPhone}
                onInput={onInput}
                onChange={onChange}
                required={true}
                hideErrors={true}
                attrs={{
                  input: '[0-9]',
                  pattern: '[0-9]{10}',
                  maxLength: 10,
                }}
              />
              <TextField 
                type="email"
                label="Email"
                name="contactEmail"
                value={formData.contactEmail}
                error={formErrors.contactEmail}
                onChange={onChange}
                required={!formData.contactMail}
                hideErrors={true}>
                {!formErrors && (
                  <FieldMessage
                    sidebar={true}
                    group={true}
                    className="show-for-large">
                    Please provide a phone number and <br/>either an email or postal address.
                  </FieldMessage>
                )}
                {formErrors && (!formData.contactPhone || (!formData.contactEmail || !formData.contactMail)) && (
                  <FieldError 
                    group={true}
                    name="contactPhone"
                    className="show-for-large"
                    error="Please provide a phone number and <br/>either an email or postal address."
                  />
                )}
              </TextField>
              <TextField 
                type="text"
                label="Mail"
                name="contactMail"
                value={formData.contactMail}
                error={formErrors.contactMail}
                required={!formData.contactEmail}
                hideErrors={true}
                onChange={onChange}
              />
              <input 
                type="text" 
                name="contactDetails"
                defaultValue={formData.contactEmail + formData.contactMail} 
                required 
                style={{display: 'none'}}
              />
              {formErrors && (!formData.contactPhone || (!formData.contactEmail || !formData.contactMail)) && (
                <FieldError 
                  name="contactPhone"
                  className="hide-for-large"
                  error="Please provide a phone number and either an email or postal address."
                />
              )}
            </FieldSet>
          </FieldItem>
        </FieldList>
        <FormFooter 
          next="Next: Complaint Details"
          onPrevious={onPrevious}
        />
      </ShowIf>
    </form>
  )
}

export default PrivacyComplaintFormStep2;
